body {
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../public/server-room-bg.webp') no-repeat center center;
  background-size: cover;
  font-family: "VT323", monospace;
  font-weight: 400;
  font-style: normal;
}

.container {
  background-color: rgba(255, 255, 255, 0.4); /* Optional to make text more readable */
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

h1 {
  margin: 0 0 20px;
  font-size: 3rem;
}

ul {
  list-style: none;
  padding: 0;
  font-weight: bold;
}

li {
  margin: 10px 0;
  font-size: 1.6rem;
}

.blinking-cursor {
  display: inline-block;
  width: 1px;
  height: 1em;
  animation: blink 1s step-end infinite;
}

@media (max-width: 600px) {
  h1 {
    font-size: 1.5rem;
  }

  li {
    font-size: 1rem;
  }
}

@keyframes blink {
  0%, 50% {
    opacity: 1;
  }
  50.1%, 100% {
    opacity: 0;
  }
}
